<script lang="ts" setup>

const isResetting = ref(false);
const resetDemo = async () => {
  const userId = useCurrentUID();

  if (!userId) return;

  isResetting.value = true;
  try {
    await $fetch(`/api/demos/reset`, {
      method: "POST",
      headers: await useApiHeaders(),
    });
  } catch (error) {
    console.log(error);
    // Empty
  }
  isResetting.value = false;
};
</script>

<template>
  <BaseTextButton :show-spinner="isResetting" @click="resetDemo">
    Reset Demo
  </BaseTextButton>
</template>

<style scoped></style>
